<template>
    <div class="container repair-container">

        <loading-state
                v-if="!assetDevice"
                title="Loading asset details">
        </loading-state>

        <div v-if="assetDevice">
            <!-- Make and model (detected from the IMEI) -->
            <div class="card card-body" v-if="!editing">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0 text-normal">{{ assetDevice.make_title }} {{ assetDevice.model_version_title }}</label>
                    <button class="btn btn-info btn-sm" @click="clearDevice()">Change</button>
                </div>
            </div>

            <template v-if="editing">
                <!-- Make -->
                <section>
                    <label>Who makes the model?</label>
                    <div class="card-grid grid-col-2 grid-col-md-3 grid-col-lg-4">
                        <div class="card-link-container" v-for="(make, index) in deviceMakes" :key="index">
                            <div class="card card-select card-select-sm horizontal justify-content-start" @click="setMake(make)" :class="{'active': selectedMake === make}">
                                <img :src="`https://likewizerepair-uk.s3.eu-west-2.amazonaws.com/makes/${make.code}/${make.code}.svg`" :alt="`${make.title}`" class="img-lg">
                                {{ make.title }}
                            </div>
                        </div>
                    </div>
                </section>

                <!-- Model -->
                <section v-if="selectedMake">
                    <label>Which model is it?</label>
                    <div class="card-grid grid-col-2 grid-col-md-3 grid-col-lg-4">
                        <div class="card-link-container" v-for="(model, index) in filteredModelList" :key="index">
                            <div class="card card-select card-select-sm" @click="setModel(model)" :class="{'active': selectedModel === model}">
                                {{ model.title }}
                            </div>
                        </div>
                    </div>

                    <!-- Load more button -->
                    <div class="row" v-if="deviceModels.length > deviceListLimit && deviceListLimit !== null">
                        <div class="col-6 offset-3 col-md-4 offset-md-4">
                            <button class="btn btn-solid-secondary btn-rounded btn-block px-4 py-2 text-sm mb-4" @click="deviceListLimit = null">Load more</button>
                        </div>
                    </div>

                </section>
            </template>

            <!-- Colour -->
            <section v-if="!editing || (editing && selectedModel)">
                <label>What is the colour and board size?</label>
                <div class="card-grid grid-col-2 grid-col-md-3 grid-col-lg-4">
                    <div class="card-link-container" v-for="(version, index) in filteredDeviceVersions" :key="index">
                        <div class="card card-select card-select-sm" @click="setVersion(version)" :class="{'active': assetColourSelected(version)}">
                            {{ version.colour_title }}
                            <span class="text-sm">{{ version.board_size }}</span>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Footer -->
            <footer class="fixed-footer" :class="{'active': selectedAssetColour}">
                <div class="container">
                    <router-link :to="{ name: 'bulkRepairDiagnostics.confirmProblems', params:{assetId: assetId}}" class="btn btn-success icon-right ml-auto">Next <i class="fa fa-arrow-right"></i></router-link>
                </div>
            </footer>
        </div>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {

        props: ['assetId'],

        data() {
            return {
                deviceListLimit: 12,
                editing: false
            }
        },

        computed: {
            ...mapGetters([
                'asset',
                'assetDevice',
                'deviceMakes',
                'selectedMake',
                'deviceTypes',
                'selectedType',
                'deviceModels',
                'selectedModel',
                'deviceColours',
                'selectedAssetColour'
            ]),

            filteredModelList() {
                return this.deviceListLimit ? this.deviceModels.slice(0,this.deviceListLimit) : this.deviceModels
            },

            filteredDeviceVersions() {
                if (Object.keys(this.deviceColours).length === 0) {
                    return {};
                }

                let filtered = this.deviceColours.filter((el) => {
                    return (el.board_size && el.board_size != "Undefined")
                });

                return filtered;
            }
        },

        mounted() {
            this.resetChangeDeviceModal();
            this.loadAsset({
                assetId: this.assetId
            }).then(() => {
                this.loadAssetDevice({
                    assetId: this.assetId,
                    ownerId: this.asset.owner_id,
                }).then(() => {
                    this.loadModelColours({
                        makeId: this.assetDevice.make_id,
                        typeId: this.assetDevice.type_id,
                        modelId: this.assetDevice.model_id,
                    });
                });
            });
        },

        methods: {
            ...mapActions([
                'loadAsset',
                'loadAssetDevice',
                'clearAssetDevice',

                'setAssetDeviceMake',
                'setAssetDeviceModel',
                'setAssetDeviceVersion',

                'resetChangeDeviceModal',

                'loadDeviceMakes',
                'setSelectedMake',
                'setSelectedType',

                'loadDeviceModels',
                'setSelectedModel',
                'clearSelectedModel',

                'loadModelColours',
                'setSelectedAssetColour',
                'clearSelectedAssetColour',

                'clearAddedAssetProblems'
            ]),

            /** Clear the device */
            clearDevice() {
                this.clearAssetDevice({
                    assetId: this.assetId,
                    ownerId: this.asset.owner_id
                }).then(() => {
                    this.editing = true;
                    this.showFooter = false;
                    this.resetChangeDeviceModal();
                    this.clearSelectedAssetColour();
                    this.clearAddedAssetProblems({
                        assetId: this.assetId,
                        ownerId: this.asset.owner_id
                    });
                    this.loadDeviceMakes();
                });
            },

            /** Set the make */
            setMake(make) {
                this.resetChangeDeviceModal().then(() => {
                    this.setAssetDeviceMake({
                        assetId: this.assetId,
                        ownerId: this.asset.owner_id,
                        make: make
                    }).then(() => {
                        this.setSelectedMake(make);
                        this.setSelectedType(1);
                        this.loadDeviceModels({
                            makeId: make.id,
                            typeId: 1
                        });
                    });
                });
            },

            /** Set the model */
            setModel(model) {
                this.setAssetDeviceModel({
                    assetId: this.assetId,
                    ownerId: this.asset.owner_id,
                    model: model
                }).then(() => {
                    this.setSelectedModel(model);
                    this.loadModelColours({
                        makeId: this.assetDevice.make_id,
                        typeId: this.assetDevice.type_id,
                        modelId: this.assetDevice.model_id,
                    });
                });
            },

            /** Set colour and board type */
            setVersion(version) {
                this.setAssetDeviceVersion({
                    assetId: this.assetId,
                    ownerId: this.asset.owner_id,
                    version: version
                }).then(() => {
                    this.setSelectedAssetColour(version);
                });
            },

            assetColourSelected(version) {
                if (!this.selectedAssetColour || !this.selectedAssetColour.version_id) {
                    return false;
                }

                return this.selectedAssetColour.version_id === version.version_id;
            }
        }
    }
</script>

<style scoped>

</style>